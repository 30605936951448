import React, { useEffect, useState } from "react";
import { LogBox, View, Text, TextInput, TouchableOpacity, Linking } from 'react-native';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import styles from "./style";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useFocusEffect } from "@react-navigation/native";
import * as Animatable from 'react-native-animatable';
import { collection, query, where, getDocs, getFirestore } from "firebase/firestore";
import firebase from "../Config/firebaseconfig";

export default function Login({ navigation }) {
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [errorRegister, setErrorRegister] = useState(false);
    const [logar, setLogar] = useState("");
    const [visu, setVisu] = useState(true);
    const [eye, setEye] = useState("eye");

    const auth = getAuth();
    const db = getFirestore();

    useEffect(() => {
        setEye(visu ? "eye-off" : "eye");
    }, [visu]);

    const login = () => {
        signInWithEmailAndPassword(auth, email.trim().toLowerCase(), senha)
            .then(() => {
                // login bem-sucedido, onAuthStateChanged vai lidar com o restante
            })
            .catch(() => {
                setErrorRegister(true);
            });
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const userEmail = user.email.trim().toLowerCase();
                const temPermissao = await verificarPermissao(userEmail);

                if (temPermissao) {
                    navigation.navigate("Home");
                } else {
                    setErrorRegister(true);
                    auth.signOut(); // Desloga quem não tem permissão
                }
            }
        });

        return unsubscribe;
    }, []);

    const verificarPermissao = async (emailVerificar) => {
        const usersRef = collection(db, "Usuarios");

        const querySuporte = query(usersRef, where("suporte", "==", "Sim"), where("email", "==", emailVerificar));
        const queryProfessor = query(usersRef, where("Professor", "==", "Sim"), where("email", "==", emailVerificar));

        const [snapshotSuporte, snapshotProfessor] = await Promise.all([
            getDocs(querySuporte),
            getDocs(queryProfessor)
        ]);

        return !snapshotSuporte.empty || !snapshotProfessor.empty;
    };

    const handleKeyPress = ({ nativeEvent: { key: keyValue } }) => {
        if (keyValue === 'Enter') {
            setLogar("Ativar");
        }
    };

    useFocusEffect(
        React.useCallback(() => {
            setSenha("");
            setErrorRegister(false);
            setLogar("");
        }, [])
    );

    useEffect(() => {
        if (logar === "Ativar") {
            login();
        }
    }, [logar]);

    return (
        <View style={styles.container}>
            <View style={styles.divImg}>
                <Animatable.Image
                    animation="fadeInDown"
                    style={styles.img}
                    source={{
                        uri: 'https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---BRANCO.png?alt=media&token=7645261d-d233-404b-bfd9-b61b172ce0d0',
                    }}
                />
            </View>

            <Animatable.Text animation="fadeInLeftBig" style={styles.title}>Login</Animatable.Text>

            <Animatable.View animation="slideInLeft">
                <TextInput
                    style={{
                        alignItems: 'center',
                        width: 500,
                        marginTop: 30,
                        padding: 10,
                        height: 60,
                        borderBottomWidth: 1,
                        borderBottomColor: "#fff",
                        marginLeft: "auto",
                        marginRight: "auto",
                        color: "#fff",
                        fontSize: 17,
                        fontWeight: "bold"
                    }}
                    placeholder="E-mail..."
                    placeholderTextColor="#fff"
                    type="text"
                    onChangeText={(text) => setEmail(text)}
                    value={email}
                />
            </Animatable.View>

            <Animatable.View
                animation="slideInRight"
                style={{
                    flexDirection: "row",
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: "center"
                }}
            >
                <TextInput
                    style={{
                        width: 500,
                        marginTop: 30,
                        padding: 10,
                        height: 60,
                        borderBottomWidth: 1,
                        borderBottomColor: "#fff",
                        color: "#fff",
                        fontSize: 17,
                        fontWeight: "bold"
                    }}
                    secureTextEntry={visu}
                    placeholder="Senha..."
                    placeholderTextColor="#fff"
                    type="text"
                    onChangeText={(text) => setSenha(text)}
                    value={senha}
                    onKeyPress={handleKeyPress}
                />
                <TouchableOpacity style={{ marginLeft: -30, marginTop: 40 }} onPress={() => setVisu(!visu)}>
                    <MaterialCommunityIcons
                        name={eye}
                        size={30}
                        color="#FFE"
                    />
                </TouchableOpacity>
            </Animatable.View>

            {errorRegister &&
                <View style={styles.contentAlert}>
                    <MaterialCommunityIcons
                        name="alert-circle"
                        size={24}
                        color="#FFE500"
                    />
                    <Text style={styles.warningAlert}>E-mail ou Senha Incorretos ou Sem Permissão</Text>
                    <Text onPress={() => {Linking.openURL("https://portal.odontologiasinfronteras.com/")}} style={styles.warningAlert}>
                        Si eres estudiante, haz clic aquí para ir al sistema correcto
                    </Text>
                </View>
            }

            <View style={{ alignSelf: 'center' }}>
                {email === "" || senha === "" ?
                    <Animatable.View animation="zoomIn">
                        <TouchableOpacity
                            disabled={true}
                            style={styles.buttonLogin}
                        >
                            <Text style={styles.textButtonLogin}>Entrar</Text>
                        </TouchableOpacity>
                    </Animatable.View>
                    :
                    <TouchableOpacity
                        style={styles.buttonLogin}
                        onPress={login}
                    >
                        <Text style={styles.textButtonLogin}>Entrar</Text>
                    </TouchableOpacity>
                }

                <Text style={styles.registation}>
                    <TouchableOpacity onPress={() => navigation.navigate("Trocadesenha")}>
                        <Text style={styles.linkSubscribe}>
                            Troca de senha
                        </Text>
                    </TouchableOpacity>
                </Text>
            </View>

            <View style={{ height: 100 }} />
        </View>
    );
}
