import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, Alert, TextInput, Linking, ActivityIndicator} from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { collection, query, where, getDocs, getFirestore, addDoc, doc, deleteDoc, Timestamp, orderBy} from "firebase/firestore";
import { getDownloadURL, getStorage, uploadBytes, ref } from "firebase/storage";
import { getAuth } from "firebase/auth";
import  * as DocumentPicker from 'expo-document-picker';
import styles from './style';



export default function Fila({ navigation }) {

    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [email, setEmail] = useState("");
    const [email3, setEmail3] = useState("");
    const [cas, setCas] = useState("");
    const [diplo, setDiplo] = useState("");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [reload, setReload] = useState("");
    const [email2, setEmail2] = useState("");
    const [dataAtual, setDataAtual] = useState("")
    const [nome, setNome] = useState("");



    const auth = getAuth();
    const user = auth.currentUser;

    const [referencia, setReferencia] = useState(null);
    const [image, setImage] = useState("");
    const [uploading2, setUploading2] = useState(false);

    const [referencia2, setReferencia2] = useState(null);
    const [image2, setImage2] = useState("");
    const [uploading3, setUploading3] = useState(false);

    const [referencia3, setReferencia3] = useState(null);
    const [image3, setImage3] = useState("");
    const [uploading4, setUploading4] = useState(false);
   
    const Tick =  () => {
        var data = new Date();

        var dia = String(data.getDate()).padStart(2, '0');
        var mes = String(data.getMonth() + 1).padStart(2, '0');
        var ano = data.getFullYear();
        setDataAtual(dia + '/' + mes + '/' + ano)

    }

    useEffect(() => {
        getResumos();
        setReload("");
        setOpen(false);
        setOpen2(false);
        setEmail(false);
        setEmail2("");
        setRetorno([])
    }, [reload])
    
    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("status", "==", 'Esperando validación de soporte'), where('ifoUsuario.diplo', 'not-in', ['Intercept Course']));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                consulta: doc.data().ifoUsuario,
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        setRetorno(array)
    }
    

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("email", "==", email), where("numeroCaso", "==", cas), where("ifoUsuario.diplo", "==", diplo), where("status", "==", "Esperando validación de soporte"), orderBy("email"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data.seconds,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                consulta: doc.data().ifoUsuario,
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        setRetorno2(array)
    }

    const pickImage = async () => {

        try{
            let result = await DocumentPicker.getDocumentAsync({
                type: "*/*",
                copyToCacheDirectory: false,
                multiple: false,
            });
          console.log(result);

          var lastThree = result.name.substr(result.name.length - 3);

          setImage(result.name)
          const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                console.log(e);
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", result.uri, true);
            xhr.send(null);
        });

        setUploading2(true);
        var url = Math.floor(Math.random() * 655366545782);
        
        const storage = getStorage();
        
        const fileImageRef = ref(storage, 'gs://teste-5e945.appspot.com/Resumos/' + url );
        const upload = await uploadBytes(fileImageRef, blob);
       
        let UrlDownload = await getDownloadURL(fileImageRef);
        setReferencia(UrlDownload)
        blob.close();
    
        } catch(error) {

        }
    }

    const pickImage2 = async () => {

        try{
            let result = await DocumentPicker.getDocumentAsync({
                type: "*/*",
                copyToCacheDirectory: false,
                multiple: false,
            });
          console.log(result);

          var lastThree = result.name.substr(result.name.length - 3);

          setImage2(result.name)
          const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                console.log(e);
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", result.uri, true);
            xhr.send(null);
        });

        setUploading3(true);
        var url = Math.floor(Math.random() * 655366545782);
        
        const storage = getStorage();
        
        const fileImageRef = ref(storage, 'gs://teste-5e945.appspot.com/Resumos/' + url );
        const upload = await uploadBytes(fileImageRef, blob);
       
        let UrlDownload = await getDownloadURL(fileImageRef);
        setReferencia2(UrlDownload)
        blob.close();
    
        } catch(error) {

        }
    }

    const pickImage3 = async () => {

        try{
            let result = await DocumentPicker.getDocumentAsync({
                type: "*/*",
                copyToCacheDirectory: false,
                multiple: false,
            });
          console.log(result);

          var lastThree = result.name.substr(result.name.length - 3);

          setImage3(result.name)
          const blob = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                resolve(xhr.response);
            };
            xhr.onerror = function (e) {
                console.log(e);
                reject(new TypeError("Network request failed"));
            };
            xhr.responseType = "blob";
            xhr.open("GET", result.uri, true);
            xhr.send(null);
        });

        setUploading4(true);
        var url = Math.floor(Math.random() * 655366545782);
        
        const storage = getStorage();
        
        const fileImageRef = ref(storage, 'gs://teste-5e945.appspot.com/Resumos/' + url );
        const upload = await uploadBytes(fileImageRef, blob);
       
        let UrlDownload = await getDownloadURL(fileImageRef);
        setReferencia3(UrlDownload)
        blob.close();
    
        } catch(error) {

        }
    }

    console.log(referencia)


    useEffect(() => {
        getResumos();
        Tick();
        if (user !== null) {
            user.providerData.forEach((profile) => {
                setEmail3(profile.email)
            });
        } 

    }, [])

    const getNome = async () => {
        
        const db = getFirestore();
        
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("email", "==", email3));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        
            setNome(doc.data().nome);
        });
    
}

    useEffect(() => {
        getResumos2();
    },[email])

    useEffect(() => {
        getNome();
    },[email3])

    useEffect(() => {
        setUploading2(false);
    },[referencia])

    useEffect(() => {
        setUploading3(false);
    },[referencia2])

    useEffect(() => {
        setUploading4(false);
    },[referencia3])

    console.log(open)

    return (
        <>
            <View style={styles.container}>
                
                <View style={styles.header}>
                    {open !== true ?
                        <View>
                            <Text style={styles.Title}>Caso Clínico</Text>
                        </View>
                        :
                        <View>
                            <Text style={styles.Title}>Caso clínico do aluno</Text>
                        </View>
                    }
                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                        <MaterialCommunityIcons
                            name="reply"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>

                    <TouchableOpacity style={{position: "absolute", top: 20, right: 60}} onPress={() => {navigation.navigate("FilaProfessor")}}>
                        <Text style={{fontSize: 50, textAlign: 'center'}}>📚</Text>
                    </TouchableOpacity>

                    <View style={{position: "absolute", top: 30, right: 150}}>
                        <Text style={{fontSize: 30, textAlign: 'center', color: "#FFF", fontWeight: "bold"}}>Nº Casos: {retorno.length}</Text>
                    </View>

                </View>
                

                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30, }}>

                {open !== true ?
                    <>
                    <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFF", margin: 20 }}>
                        <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold" }}>Aguardando validação</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                    }} onPress={() => navigation.navigate("Geral")}>

                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Planilha Geral</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                    }} onPress={() => navigation.navigate("ConsultaAluno")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Consulta de casos clínicos</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                    }} onPress={() => navigation.navigate("FilaCasosDAO")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Casos DAO (Fila)</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={{
                        backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                    }} onPress={() => navigation.navigate("ConsultaSuporte")}>
                        <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Casos DAO (Aprovados)</Text>
                    </TouchableOpacity>

                    </>
                    :
                    <View style={{justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30}}>
                            <TouchableOpacity onPress={() => {setOpen(false), setEmail(""), setCas("")}}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}>Aguardando validação</Text>
                            </TouchableOpacity>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}> -{'>'} </Text>
                            <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold"}}>Documentos Enviados</Text>
                        </View>
                    }
                </View>

                {open === true &&
                <View>
                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>
                        
                            <View style={styles.divReturn}>
                                <Text style={{position: "absolute", top: 0, right: 0, marginRight: 25, marginTop: 17}}>Nº Caso Clínico: {item.numeroCaso}</Text>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>
                                <Text style={styles.textReturnEmail2}>{item.Envio}        {item.diplo}</Text>
                            </View>

                            <View style={styles.viewInicial}>
                                <TouchableOpacity style={styles.buttonView1} onPress={() => {
                                        Linking.openURL(item.Anamnesis);
                                        }}>
                                        <Text style={styles.textView}>📄 Guía de Anamnesis</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.buttonView2} onPress={() => {
                                        Linking.openURL(item.DocPaciente);
                                        }}>
                                        <Text style={styles.textView}>📄 Documentação do Paciente</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.buttonView3} onPress={() => {
                                        Linking.openURL(item.CefalometriaPaciente);
                                        }}>
                                        <Text style={styles.textView}>📄 Cefalometría</Text>
                                </TouchableOpacity>
                                </View>

                                <View style={styles.viewInicial}>

                                    <TouchableOpacity style={styles.buttonProblema} onPress={() => open2 === false ? setOpen2(true): setOpen2(false)}>

                                        {open2 === false &&
                                        <MaterialCommunityIcons
                                            name="close"
                                            size={25}
                                            color="#000000"
                                            style={{ alignSelf: 'center' }}
                                        />
                                        }

                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#000000', }}>{open2 === false ? "Reprovar" : "Voltar"}</Text>

                                    </TouchableOpacity>

                                {open2 === false &&
                                    <TouchableOpacity style={styles.buttonOk} onPress={async () => {

                                        var n;

                                        if (item.numeroCaso === 1){
                                            n = "first"
                                        } else if (item.numeroCaso === 2) {
                                            n = "second"
                                        } else if (item.numeroCaso === 3) {
                                            n = "third"
                                        } else {
                                            n = ""
                                        }

                                        if (item.diplo !== "Intercept Course") {
                                            const db = getFirestore();
                                            const docRef = await addDoc(collection(db, "Resumos"), {
                                                to: [item.email],
                                                message: {
                                                    subject: 'Su ' + (item.numeroCaso) +'º caso clínico fue aprobado',
                                                    html: '<p>Hola Doc. ' + (item.nome) + ', ¡saludos! </p><p>Recibimos su caso clínico. La documentación enviada será analizada por el equipo de profesores y pronto nos pondremos en contacto para dar la devolutiva planificación del caso clínico.</p><p>Seguimos en contacto.</p><p>Un abrazo,</p><p>'+(nome)+'</p><p>Equipo de Soporte Profª Rayane Pinto<br/></p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                                },
                                                email: item.email,
                                                ifoUsuario: item.consulta,
                                                Anamnesis: item.Anamnesis,
                                                DocPaciente: item.DocPaciente,
                                                CefalometriaPaciente: item.CefalometriaPaciente,
                                                data: Timestamp.fromDate(new Date()),
                                                Envio: item.Envio,
                                                numeroCaso: item.numeroCaso,
                                                status: "Esperando análisis de los profesores", 
                                                validata: dataAtual,
                                                initData: "",
                                                Prof: "",
                                                Planej: "",
                                                face: "",
                                                tipo: "CASO",
                                                Motivo: item.Motivo,
                                                Arquivo: item.Arquivo
                                            });
                                        
                                        } else {

                                            const db = getFirestore();
                                            const docRef = await addDoc(collection(db, "Resumos"), {
                                                to: [item.email],
                                                message: {
                                                    subject: 'Intercept Course - Your ' + (n) +' clinical case was approved',
                                                    html: '<p>Hello Doctor ' + (item.nome) + ', how are you doing? </p><p>We have good news for you!</p><p>Your clinical case has been approved. Now, the documentation sent will be analyzed by our team of teachers and soon we will be in contact to give you the devolutive planning of the clinical case.</p><p>We will keep in touch</p><p>Best Regards</p><p>Doctora Rayane Pinto´s Support Team<br/></p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                                },
                                                email: item.email,
                                                ifoUsuario: item.consulta,
                                                Anamnesis: item.Anamnesis,
                                                DocPaciente: item.DocPaciente,
                                                CefalometriaPaciente: item.CefalometriaPaciente,
                                                data: Timestamp.fromDate(new Date()),
                                                Envio: item.Envio,
                                                numeroCaso: item.numeroCaso,
                                                status: "Esperando análisis de los profesores", 
                                                validata: dataAtual,
                                                initData: "",
                                                Prof: "",
                                                Planej: "",
                                                face: "",
                                                tipo: "CASO",
                                                Motivo: item.Motivo,
                                                Arquivo: item.Arquivo
                                            });
                                        }

                                        const db = getFirestore();
                                        
                                        await deleteDoc(doc(db, "Resumos", item.id));
                                        alert("Email de aprovação enviado para o aluno", "...")
                                       
                                        setReload("Recarrega")
                                    }}>

                                        <MaterialCommunityIcons
                                            name="check-bold"
                                            size={25}
                                            color="#000000"
                                            style={{ alignSelf: 'center' }}
                                        />
                                        <Text style={{ textAlign: 'center', fontSize: 15, color: '#000000', }}>Aprovar</Text>
                                    </TouchableOpacity>
                                }
                                </View>

                                <View style={styles.viewReprovar}>
                                    {open2 === true &&

                                        <TextInput style={styles.input}
                                            placeholder="Motivo de reprovação..."
                                            placeholderTextColor="#000000"
                                            type="text"
                                            multiline = {true}
                                            numberOfLines = {4}
                                            onChangeText={(text) => setEmail2(text)}
                                            value={email2} />
                                    }


                                    <View style={{flexDirection: 'row'}}>

                                    {open2 === true && uploading2 === false && referencia === null ?
                                
                                    <TouchableOpacity style={styles.botaoAnexo} onPress={pickImage}>
                                        <Text style={styles.textAnexo}>Anexar Imagem</Text>
                                    </TouchableOpacity>
                                    :
                                    <ActivityIndicator style={{ margin: 25 }} animating={uploading2} size={60} color="#FFF" />
                                    }

                                    {open2 === true && uploading2 === false && referencia !==  null &&
                                    <View style={styles.viewUpload}>
                                        <Text style={styles.nomeImg}>📄 {image}</Text>
                                
                                        <TouchableOpacity onPress={() => {setReferencia(null), setImage(null)}} style={styles.botaoEliminar}>
                                            <Text style={styles.textEliminar}>X</Text>
                                        </TouchableOpacity>
                
                                    </View>
                                    
                                    }

                                    {open2 === true && uploading3 === false && referencia2 === null ?
                                    
                                    <TouchableOpacity style={styles.botaoAnexo} onPress={pickImage2}>
                                        <Text style={styles.textAnexo}>Anexar Imagem</Text>
                                    </TouchableOpacity>
                                    :
                                    <ActivityIndicator style={{ margin: 25 }} animating={uploading3} size={60} color="#FFF" />
                                    }

                                    {open2 === true && uploading3 === false && referencia2 !==  null &&
                                    <View style={styles.viewUpload}>
                                        <Text style={styles.nomeImg}>📄 {image2}</Text>
                                
                                        <TouchableOpacity onPress={() => {setReferencia2(null), setImage2(null)}} style={styles.botaoEliminar}>
                                            <Text style={styles.textEliminar}>X</Text>
                                        </TouchableOpacity>
                
                                    </View>
                                    
                                    }

                                    {open2 === true && uploading4 === false && referencia3 === null ?
                                        
                                        <TouchableOpacity style={styles.botaoAnexo} onPress={pickImage3}>
                                            <Text style={styles.textAnexo}>Anexar Imagem</Text>
                                        </TouchableOpacity>
                                        :
                                        <ActivityIndicator style={{ margin: 25 }} animating={uploading4} size={60} color="#FFF" />
                                    }

                                    {open2 === true && uploading4 === false && referencia3 !==  null &&
                                    <View style={styles.viewUpload}>
                                        <Text style={styles.nomeImg}>📄 {image3}</Text>
                                
                                        <TouchableOpacity onPress={() => {setReferencia3(null), setImage3(null)}} style={styles.botaoEliminar}>
                                            <Text style={styles.textEliminar}>X</Text>
                                        </TouchableOpacity>
                
                                    </View>
                                    
                                    }
                                    </View>

                                    {open2 === true && email2 === "" &&

                                        <TouchableOpacity style={styles.botaoEnv}>
                                            <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Enviar</Text>
                                        </TouchableOpacity>

                                    }

                                    {open2 === true && email2 !== "" &&

                                        <TouchableOpacity style={styles.buttonProblema2} onPress={async () => {

                                            var n;

                                            if (item.numeroCaso === 1){
                                                n = "first"
                                            } else if (item.numeroCaso === 2) {
                                                n = "second"
                                            } else if (item.numeroCaso === 3) {
                                                n = "third"
                                            } else {
                                                n = ""
                                            }

                                            if (item.diplo !== "Intercept Course") {

                                                const db = getFirestore();
                                                const docRef = await addDoc(collection(db, "Resumos"), {
                                                    to: [item.email],
                                                    message: {
                                                        subject: 'Su ' + (item.numeroCaso) +'º caso clínico fue reprobado',
                                                        html: '<p>Hola Doc. ' + (item.nome) + ', ¡saludos! </p><p> Su caso clínico no fue aceptado</p><p>Motivo de la desaprobación: ' + email2 + '</p><p>Un abrazo,</p><p>'+(nome)+'</p><p>Seguimos en contacto.</p><p>Equipo de Soporte Profª Rayane Pinto<br/></p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                                        attachments: [{
                                                        filename: "arquivo1.png",
                                                        path:referencia
                                                        },
                                                        {
                                                            filename: "arquivo2.png",
                                                            path:referencia2
                                                        },
                                                        {
                                                            filename: "arquivo3.png",
                                                            path:referencia3
                                                        }
                                                    ]
                                                    },
                                                    email: item.email,
                                                    ifoUsuario: item.consulta,
                                                    Anamnesis: item.Anamnesis,
                                                    DocPaciente: item.DocPaciente,
                                                    CefalometriaPaciente: item.CefalometriaPaciente,
                                                    data: Timestamp.fromDate(new Date()),
                                                    status: "Reprobado por el soporte",
                                                    Envio: item.Envio,
                                                    numeroCaso: item.numeroCaso, 
                                                    validata: dataAtual,
                                                    initData: "",
                                                    Prof: "",
                                                    Planej: "",
                                                    face: "",
                                                    tipo: "CASO",
                                                    Motivo: item.Motivo,
                                                    Arquivo: item.Arquivo
                                                });
                                            } else {
                                                const db = getFirestore();
                                                const docRef = await addDoc(collection(db, "Resumos"), {
                                                    to: [item.email],
                                                    message: {
                                                        subject: 'Intercept Course - Your ' + (n) + ' clinical case was reproved',
                                                        html: '<p>Hello Doctor ' + (item.nome) + ', how are you doing?</p><p>Unfortunately your clinical case was not accepted.</p><p>The reason for disapproval is: ' + email2 + '</p><p>Best Regards</p><p>Doctora Rayane Pinto´s Support Team<br/></p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                                        attachments: [{
                                                        filename: "arquivo1.png",
                                                        path:referencia
                                                        },
                                                        {
                                                            filename: "arquivo2.png",
                                                            path:referencia2
                                                        },
                                                        {
                                                            filename: "arquivo3.png",
                                                            path:referencia3
                                                        }
                                                    ]
                                                    },
                                                    email: item.email,
                                                    ifoUsuario: item.consulta,
                                                    Anamnesis: item.Anamnesis,
                                                    DocPaciente: item.DocPaciente,
                                                    CefalometriaPaciente: item.CefalometriaPaciente,
                                                    data: Timestamp.fromDate(new Date()),
                                                    status: "Reprobado por el soporte",
                                                    Envio: item.Envio,
                                                    numeroCaso: item.numeroCaso, 
                                                    validata: dataAtual,
                                                    initData: "",
                                                    Prof: "",
                                                    Planej: "",
                                                    face: "",
                                                    tipo: "CASO",
                                                    Motivo: item.Motivo,
                                                    Arquivo: item.Arquivo
                                                });
                                            }

                                            const db = getFirestore();
                                            
                                            const docRef3 = await addDoc(collection(db, "ConsultaErro"), {
                                                email: item.email,
                                                numeroCaso: item.numeroCaso, 
                                                Prof: "",
                                                Envio: item.Envio,
                                                validata: dataAtual,
                                                emailProf: "",
                                                Anamnesis: item.Anamnesis,
                                                DocPaciente: item.DocPaciente,
                                                CefalometriaPaciente: item.CefalometriaPaciente
                                            });

                                            const docRef2 = await addDoc(collection(db, "Historico"), {
                                                email: item.email,
                                                ifoUsuario: item.consulta,
                                                Anamnesis: item.Anamnesis,
                                                DocPaciente: item.DocPaciente,
                                                CefalometriaPaciente: item.CefalometriaPaciente,
                                                data: Timestamp.fromDate(new Date()),
                                                status: "Reprobado por el soporte",
                                                Envio: item.Envio,
                                                numeroCaso: item.numeroCaso, 
                                                validata: dataAtual,
                                                initData: "",
                                                Prof: "",
                                                Planej: "",
                                                face: "",
                                                tipo: "CASO",
                                                Motivo: item.Motivo,
                                                Arquivo: item.Arquivo
                                            });

                                            await deleteDoc(doc(db, "Resumos", item.id));
                                            setReload("Recarrega")
                                            alert("Email de reprovação enviado para o aluno", "...")
                                        

                                        }}>
                                            <Text style={{ textAlign: 'center', fontSize: 15, color: '#fff', }}>Enviar</Text>
                                        </TouchableOpacity>
                                    }

                            </View>
   
                        </>
                    )
                    })}
                </View>
            }

            <View style={{justifyContent: "center",  alignItems: "center"}}>          
            <ScrollView horizontal={true} style={{flex: 1}}>
            {open ===  false &&
                <View style={{margin: 30,}}>
                    <View style={{flexDirection: 'row', justifyContent: "center", alignItems: "center" }}>
                    <View style={{backgroundColor: "#FAEDB5", width: 400, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Nome do aluno</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 450, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Email</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Grupo</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Diplomado</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>No. Caso Clínico</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Recebido Em:</Text>
                        </View>
                        
                        <View style={{backgroundColor: "#FAEDB5", width: 260, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Abrir Documentos</Text>
                        </View>
                        </View>

                        {retorno && retorno.map((item) => {
                        return (
                            <>
                                <View style={{flexDirection: 'row', }}>
                                    <View style={{backgroundColor: "#EDEDF4", width: 400, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.nome}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 450, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.email}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{(item.grupo).replace(/Grupo(\d+)/g, "Grupo $1")}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.diplo}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.numeroCaso}</Text>
                                    </View>

                                    <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>
                                        <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.Envio}</Text>
                                    </View>

                                    
                                    <View style={{backgroundColor: "#5077A1", width: 260, height: 60, margin: 8, borderRadius: 15, justifyContent: 'center', alignItems: 'center'}}>

                                        <TouchableOpacity onPress={() => {
                                            setEmail(item.email),
                                            setCas(item.numeroCaso),
                                            setDiplo(item.diplo),
                                            setOpen(true)
                                        }}>
                                            <Text style={{color: "#fff", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Ver Caso Clínico</Text>
                                        </TouchableOpacity>
                                    </View>

                                </View>
                            </>
                        )
                        })}
                </View>
            }
            </ScrollView>
            </View>  

            </View>
        </>
    )

}