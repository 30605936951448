import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, TextInput, ScrollView, Linking } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import styles from './style';
import * as Animatable from 'react-native-animatable'
import { collection, query, where, getDocs, getFirestore, orderBy, addDoc, doc, deleteDoc} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import axios from "axios";


export default function FormulaAprovados({ navigation }) {

    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [email, setEmail] = useState([]);
    const [email2, setEmail2] = useState("");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const [pesquisa, setPesquisa] = useState(false);
    const [emailConsulta, setEmailConsulta] = useState("");

    const auth = getAuth();
    const user = auth.currentUser;

    const [email3, setEmail3] = useState("");
    const [nome, setNome] = useState("");

    const url = "https://img1.niftyimages.com/lmih/wkur/x8o5?Nome="

    
    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", "Concluido"), where("diplo", "==", "Formula"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                diplo: doc.data().diplo,
                telefone: doc.data().telefone,
                pais: doc.data().pais,
                cert: doc.data().cert,
                doc: doc.data().doc,
                grupo: doc.data().grupo
            };
            array.push(Obj)
        });

        setRetorno(array)
    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", "Concluido"), where("diplo", "==", "Formula"), where("email", "==", email), orderBy("email"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                diplo: doc.data().diplo,
                telefone: doc.data().telefone,
                pais: doc.data().pais,
                cert: doc.data().cert,
                doc: doc.data().doc,
                grupo: doc.data().grupo
            };

            array.push(Obj)
        });

        setRetorno2(array)
    }

    const getResumos3 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("nome", ">=", emailConsulta), where("nome", "<=", emailConsulta + "\uf8ff"), where("tipo", "==", "Concluido"), where("diplo", "==", "Formula"));
        const q2 = query(citiesRef, where("email", ">=", emailConsulta), where("email", "<=", emailConsulta + "\uf8ff"), where("tipo", "==", "Concluido"), where("diplo", "==", "Formula"));
        let array = [];

        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                diplo: doc.data().diplo,
                telefone: doc.data().telefone,
                pais: doc.data().pais,
                cert: doc.data().cert,
                doc: doc.data().doc,
                grupo: doc.data().grupo
            };
            array.push(Obj)
        });

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                diplo: doc.data().diplo,
                telefone: doc.data().telefone,
                pais: doc.data().pais,
                cert: doc.data().cert,
                doc: doc.data().doc,
                grupo: doc.data().grupo
            };
            array.push(Obj)
        });


        setRetorno(array)
    }

    console.log(retorno)

    useEffect(() => {
        getResumos();

        if (user !== null) {
            user.providerData.forEach((profile) => {
                setEmail3(profile.email)
            });
        } 
    }, [])

    
    const getNome = async () => {
        
        const db = getFirestore();
        
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("email", "==", email3));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        
            setNome(doc.data().nome);
        });
    
}

    const handleKeyPress = ({ nativeEvent: { key: keyValue } }) => {
        if(keyValue === 'Enter'){  
        if(pesquisa === true){
            setPesquisa(false)
        }else{
            setPesquisa(true)
        }
        }
    };

    useEffect(() => {
        getResumos3();
    }, [pesquisa])

    useEffect(() => {
        getResumos2();
    },[email])
    
    useEffect(() => {
        getNome();
    },[email3])

    useEffect(() => {
        getResumos();
    }, [open])

    return (

        <View style={styles.container}>
        
        <View style={{flex: 1}}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Certificados - Fórmula</Text>
                </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={40}
                        color="#ffffff"
                        style={{justifyContent: 'center', alignContent: 'center' }}
                    />
                </TouchableOpacity>

                <View style={styles.navBar}>

                    <TouchableOpacity style={styles.NavDesable} onPress={() => navigation.navigate("Formula")}> 
                        <Text style={styles.textNavDesable}>Fila</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.NavActive}>
                        <Text style={styles.textNavActive}>Aprovados</Text>
                    </TouchableOpacity>
                </View>
                
            </View>

            {open === true &&
                <View>
                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>

                            <TouchableOpacity style={styles.buttonFechar}
                                    onPress={() => {setOpen(false)}}
                                >
                                    <Text style={styles.textButtonFechar}>X</Text>
                            </TouchableOpacity>

                        
                            <View style={styles.divReturn}>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>
                                <Text style={styles.textReturnEmail2}>{item.diplo}</Text>
                            </View>


                            <View style={styles.alinhadorDocs}>

                                <Text style={styles.textDoc}>Documento:</Text>

                                <ScrollView horizontal={true}>
                                    {item.doc[0] !== "" &&
                                        <TouchableOpacity style={styles.buttonDoc} onPress={() => {Linking.openURL(item.doc[0]);}}>
                                            <Text style={styles.textButtonDoc}>📄 Primeira Foto</Text>
                                        </TouchableOpacity>
                                    }

                                    {item.doc[1] !== "" &&
                                        <TouchableOpacity style={styles.buttonDoc} onPress={() => {Linking.openURL(item.doc[1]);}}>
                                            <Text style={styles.textButtonDoc}>📄 Segunda Foto</Text>
                                        </TouchableOpacity>
                                    }
                                </ScrollView>

                            </View>


                            <View style={styles.alinhadorDocs}>

                                <Text style={styles.textDoc}>Certificado:</Text>

                                <ScrollView horizontal={true}>
                                    {item.cert[0] !== "" &&
                                        <TouchableOpacity style={styles.buttonDoc} onPress={() => {Linking.openURL(item.cert[0]);}}>
                                            <Text style={styles.textButtonDoc}>📄 Primeira Foto</Text>
                                        </TouchableOpacity>
                                    }

                                    {item.cert[1] !== "" &&
                                        <TouchableOpacity style={styles.buttonDoc} onPress={() => {Linking.openURL(item.cert[1]);}}>
                                            <Text style={styles.textButtonDoc}>📄 Segunda Foto</Text>
                                        </TouchableOpacity>
                                    }
                                </ScrollView>

                            </View>

                            <View style={styles.alinhadorCet}>
                                <Image
                                    style={{width: 688, height: 960}}
                                    source={{uri: url + item.nome}}
                                />
                            </View>
                        </>
                    )
                    })}
                </View>
            }

           {open === false &&
                <>

                    <View style={{alignSelf: "center", marginTop: 60,}}>
                        <View style={{flexDirection: 'row'}}>
                            <TextInput 
                            style={{
                                alignItems: 'center', width: 500, marginTop: 30, padding: 10,
                                height: 60, marginLeft: "auto", marginRight: "auto", color: "#000", 
                                fontSize: 17, backgroundColor: "#FFF", borderRadius: 20
                            }}
                                placeholder="E-mail do Aluno..."
                                placeholderTextColor="#000"
                                type="text"
                                onChangeText={(text) => setEmailConsulta(text)}
                                value={emailConsulta} 
                                onKeyPress={handleKeyPress}
                                />
                        

                            <TouchableOpacity style={{backgroundColor: "#5077A1", width: 60, height: 60, borderRadius: 30, marginLeft: 20, marginTop: 30}} onPress={() => pesquisa === true ? setPesquisa(false) : setPesquisa(true) }>
                                <MaterialCommunityIcons
                                    name="magnify"
                                    size={30}
                                    color="#FFF"
                                    style={{alignItems: "center", alignContent: "center", alignSelf: "center", marginTop: 13}}
                                />
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={{flexDirection: 'row', justifyContent: "center", marginTop: 80}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 520, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Nome do aluno</Text>
                            </View>
                            <View style={{backgroundColor: "#FAEDB5", width: 500, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Email</Text>
                            </View>
                            <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Diplomado</Text>
                            </View>

                            <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Grupo</Text>
                            </View>

                            <View style={{backgroundColor: "#FAEDB5", width: 260, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Visualizar</Text>
                            </View>
                    
                        </View>

                    
                            <ScrollView 
                                showsVerticalScrollIndicator={false}
                                style={{ marginBottom: 30}}
                            >

                                {retorno && retorno.map((item) => {
                                    return (
                                        <>
                                            <View style={{flexDirection: 'row', justifyContent: "center", cursor: "crosshair"}}>
                                                <View style={{backgroundColor: "#EDEDF4", width: 520, height: 60, margin: 5, borderRadius: 15}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.nome}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 500, height: 60, margin: 5, borderRadius: 15}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.email}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.diplo}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.grupo}</Text>
                                                </View>
                
                                                <TouchableOpacity style={{backgroundColor: "#5077A1", width: 260, height: 60, margin: 5, borderRadius: 15}}
                                                    onPress={() => {setOpen(true), setEmail(item.email)}}
                                                >
                                                    <Text style={{color: "#fff", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Abrir</Text>
                                                </TouchableOpacity>
                                            
                                            </View>

                                        </>
                                )})}
                            </ScrollView>
                        </>
                        }
                    
            </View>
        </View>
    )
}