import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, ScrollView, Alert, TextInput, Linking} from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import { collection, query, where, getDocs, getFirestore, addDoc, doc, deleteDoc, orderBy} from "firebase/firestore";
import { useFocusEffect } from '@react-navigation/native';
import styles from './style';



export default function ConsultaAluno({ navigation }) {

    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [email, setEmail] = useState("");
    const [diplo, setDiplo] = useState("");
    const [cas, setCas] = useState("");
    const [validata, setValidata] = useState("");
    const [open, setOpen] = useState(false);
    const [pesquisa, setPesquisa] = useState(false)
    
    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("email", "==", email),  where("tipo", "==", "CASO"), where("data", "!=", null), orderBy("data"));
        const q2 = query(citiesRef, where("ifoUsuario.nome", ">=", email), where("ifoUsuario.nome", "<=", email + "\uf8ff"), where("tipo", "==", "CASO"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        const querySnapshot2 = await getDocs(q2);
        querySnapshot2.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                tipo: "CASO",
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        setRetorno(array)
    }

    
    

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("email", "==", email), where("numeroCaso", "==", cas), where("ifoUsuario.diplo", "==", diplo), where("validata", "==", validata), orderBy("email"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
            console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().ifoUsuario.nome,
                grupo: doc.data().ifoUsuario.grupo,
                diplo: doc.data().ifoUsuario.diplo,
                status: doc.data().status,
                numeroCaso: doc.data().numeroCaso,
                Anamnesis: doc.data().Anamnesis,
                CefalometriaPaciente: doc.data().CefalometriaPaciente,
                DocPaciente: doc.data().DocPaciente,
                data: doc.data().data,
                validata: doc.data().validata,
                initData: doc.data().initData,
                Prof: doc.data().Prof,
                Planej: doc.data().Planej,
                face: doc.data().face,
                Envio: doc.data().Envio,
                Arquivo: doc.data().Arquivo,
                Motivo: doc.data().Motivo,
            };
            array.push(Obj)
        });

        setRetorno2(array)
    }

    const handleKeyPress = ({ nativeEvent: { key: keyValue } }) => {
        if(keyValue === 'Enter'){  
          if(pesquisa === true){
            setPesquisa(false)
          }else{
            setPesquisa(true)
          }
        }
    };
    

    useEffect(() => {
        getResumos();
    }, [pesquisa])

    useEffect(() => {
        getResumos2();
    },[open])

    return (
        <>
            <View style={styles.container}>
                
                <View style={styles.header}>
                    {open !== true ?
                    <View>
                        <Text style={styles.Title}>Casos Clínicos</Text>
                    </View>
                    :
                    <View>
                        <Text style={styles.Title}>Caso clínico do aluno</Text>
                    </View>
                    }
                    <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                        <MaterialCommunityIcons
                            name="reply"
                            size={40}
                            color="#ffffff" />
                    </TouchableOpacity>

                    <View style={{position: "absolute", top: 30, right: 150}}>
                        <Text style={{fontSize: 30, textAlign: 'center', color: "#FFF", fontWeight: "bold"}}>Nº Casos: {retorno.length}</Text>
                    </View>

                </View>
                

                <View style={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30 }}>

                    {open !== true ?
                        <>
                             <TouchableOpacity style={{ backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20 }} onPress={() => navigation.navigate("Fila")}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Aguardando validação</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={{
                                backgroundColor: "transparent", borderBottomWidth: 1, borderBottomColor: "#FFFFFF70", margin: 20
                            }}  onPress={() => navigation.navigate("Geral")}>

                                <Text style={{  color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Planilha Geral</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={{
                                 backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFF", margin: 20,}} 
                            >
                                <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold" }}>Consulta de casos clínicos</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={{
                                backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                            }} onPress={() => navigation.navigate("FilaCasosDAO")}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Casos DAO (Fila)</Text>
                            </TouchableOpacity>

                            <TouchableOpacity style={{
                                backgroundColor: "transparent", borderBottomWidth: 1.5, borderBottomColor: "#FFFFFF70", margin: 20,
                            }} onPress={() => navigation.navigate("ConsultaSuporte")}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold" }}>Casos DAO (Aprovados)</Text>
                            </TouchableOpacity>
                        </>
                        :
                        <View style={{justifyContent: 'center', alignItems: 'center', alignContent: 'center', flexDirection: 'row', marginTop: 30, marginBottom: 30}}>
                            <TouchableOpacity onPress={() => {setOpen(false), setEmail(""), setCas("")}}>
                                <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}>Planilha Geral</Text>
                            </TouchableOpacity>
                            <Text style={{ color: "#FFFFFF70", fontSize: 25, fontWeight: "bold"}}> -{'>'} </Text>
                            <Text style={{ color: "#FFF", fontSize: 25, fontWeight: "bold"}}>Documentos Enviados</Text>
                        </View>
                    }

                </View>

                {open === true &&
                <View>
                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>
                        
                            <View style={styles.divReturn}>
                                <Text style={{position: "absolute", top: 0, right: 0, marginRight: 25, marginTop: 17}}>Nº Caso Clínico: {item.numeroCaso}</Text>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>
                                <Text style={styles.textReturnEmail2}>{item.Envio}        {item.diplo}</Text>

                            </View>

                            <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', alignContent: 'center', marginBottom: 30, marginTop: 5 }}>
                                
                                <TouchableOpacity style={styles.buttonView1} onPress={() => {
                                        Linking.openURL(item.Anamnesis);
                                        }}>
                                        <Text style={styles.textView}>📄 Guía de Anamnesis</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.buttonView2} onPress={() => {
                                        Linking.openURL(item.DocPaciente);
                                        }}>
                                        <Text style={styles.textView}>📄 Documentação do Paciente</Text>
                                </TouchableOpacity>

                                <TouchableOpacity style={styles.buttonView3} onPress={() => {
                                        Linking.openURL(item.CefalometriaPaciente);
                                        }}>
                                        <Text style={styles.textView}>📄 Cefalometría</Text>
                                </TouchableOpacity>
                                </View>

                        </>
                    )
                    })}
                </View>
            }

            {open ===  false &&
                <View style={{alignSelf: "center", marginBottom: 20}}>
                    <View style={{flexDirection: 'row'}}>
                        <TextInput 
                        style={{
                            alignItems: 'center', width: 500, marginTop: 30, padding: 10,
                            height: 60, marginLeft: "auto", marginRight: "auto", color: "#000", 
                            fontSize: 17, backgroundColor: "#FFF", borderRadius: 20
                        }}
                            placeholder="E-mail ou Nome..."
                            placeholderTextColor="#000"
                            type="text"
                            onChangeText={(text) => setEmail(text)}
                            value={email} 
                            onKeyPress={handleKeyPress}
                            />
                    

                        <TouchableOpacity style={{backgroundColor: "#5077A1", width: 60, height: 60, borderRadius: 30, marginLeft: 20, marginTop: 30}} onPress={() => pesquisa === true ? setPesquisa(false) : setPesquisa(true) }>
                            <MaterialCommunityIcons
                                name="magnify"
                                size={30}
                                color="#FFF"
                                style={{alignItems: "center", alignContent: "center", alignSelf: "center", marginTop: 13}}
                            />
                        </TouchableOpacity>
                    </View>
                </View>
            }

               
            <ScrollView horizontal={true} style={{flex:1, marginLeft: 30, marginRight: 30}}>
            {open ===  false &&
                <View style={{margin: 30, }}>
                <View style={{flexDirection: 'row'}}>
                        <View style={{backgroundColor: "#FAEDB5", width: 400, height: 60, margin: 5, borderRadius: 15}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Nome do aluno</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 450, height: 60, margin: 5, borderRadius: 15}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Email</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 170, height: 60, margin: 5, borderRadius: 15}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Grupo</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 180, height: 60, margin: 5, borderRadius: 15}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Diplomado</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 10, fontWeight: "bold"}}>No. Caso Clínico</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 170, height: 60, margin: 5, borderRadius: 15}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Recebido Em:</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 170, height: 60, margin: 5, borderRadius: 15}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 10, fontWeight: "bold"}}>Validado Em: (Suporte)</Text>
                        </View>
                        <View  style={{backgroundColor: "#FAEDB5", width: 200, height: 60, margin: 5, borderRadius: 15}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Status:</Text>
                        </View>
                        <View  style={{backgroundColor: "#FAEDB5", width: 200, height: 60, margin: 5, borderRadius: 15}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Professor:</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 250, height: 60, margin: 5, borderRadius: 15}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 10, fontWeight: "bold"}}>Arquivos Anexados: (Aluno)</Text>
                        </View>
                        <View style={{backgroundColor: "#FAEDB5", width: 250, height: 60, margin: 5, borderRadius: 15}}>
                            <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 10, fontWeight: "bold"}}>Caso Planejado: (Professor)</Text>
                        </View>
                       
                    </View>

                    {retorno && retorno.map((item) => {
                    return (
                        <>
                            <View style={{flexDirection: 'row'}}>
                            <View style={{backgroundColor: "#EDEDF4", width: 400, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.nome}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 450, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.email}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 170, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.grupo}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 180, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.diplo}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.numeroCaso}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 170, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.Envio}</Text>
                                </View>
                                
                                <View style={{backgroundColor: "#EDEDF4", width: 170, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.validata}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 200, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 16, marginTop: 20, fontWeight: "bold"}}>{item.status}</Text>
                                </View>

                                <View style={{backgroundColor: "#EDEDF4", width: 200, height: 60, margin: 5, borderRadius: 15}}>
                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.Prof}</Text>
                                </View>

                                <View style={{backgroundColor: "#5077A1", width: 250, height: 60, margin: 5, borderRadius: 15}}>

                                    <TouchableOpacity onPress={() => {
                                        setEmail(item.email),
                                        setCas(item.numeroCaso),
                                        setDiplo(item.diplo),
                                        setValidata(item.validata),
                                        setOpen(true)
                                    }}>
                                        <Text style={{color: "#fff", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Documentos Enviados</Text>
                                    </TouchableOpacity>
                                </View>

                                {item.Arquivo !== "" ?
                                <TouchableOpacity onPress={() => { Linking.openURL(item.Arquivo) }}>
                                    <View style={{backgroundColor: "#5077A1", width: 250, height: 60, margin: 5, borderRadius: 15}}>
                                        <Text style={{color: "#FFF", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Clique para Ver</Text>
                                    </View>
                                </TouchableOpacity>
                                :
                                <View style={{width: 250, height: 60, margin: 5, borderRadius: 15}}>
                                  
                                </View>
                                }

                            </View> 
                         
                                
                        </>
                    )
                    })}
               </View>
            }
            </ScrollView>
       
            </View>
        </>
    )

}