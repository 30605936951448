import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, TextInput, ScrollView, Linking } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import styles from './style';
import * as Animatable from 'react-native-animatable'
import { collection, query, where, getDocs, getFirestore, orderBy, addDoc, doc, deleteDoc} from "firebase/firestore";
import firebase from "../../../Config/firebaseconfig";
import { getAuth } from "firebase/auth";
import axios from "axios";


export default function InterceptCourse({ navigation }) {

    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [email, setEmail] = useState([]);
    const [email2, setEmail2] = useState("");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);

    const auth = getAuth();
    const user = auth.currentUser;

    const [email3, setEmail3] = useState("");
    const [nome, setNome] = useState("");

    const url = "https://img1.niftyimages.com/lmih/wkur/x8o5?Nome="

    
    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", "Certificado"), where("diplo", "==", "Intercept Course"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                diplo: doc.data().diplo,
                telefone: doc.data().telefone,
                pais: doc.data().pais,
                cert: doc.data().cert,
                doc: doc.data().doc,
                grupo: doc.data().grupo,
                data: doc.data().delivery
            };
            array.push(Obj)
        });
        // Ordena o array baseado no campo endTime (do mais antigo para o mais recente)
        array.sort((a, b) => {
            return a.data.endTime.seconds - b.data.endTime.seconds;
        });

        setRetorno(array);
    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", "Certificado"), where("diplo", "==", "Intercept Course"), where("email", "==", email), orderBy("email"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                nome: doc.data().nome,
                diplo: doc.data().diplo,
                telefone: doc.data().telefone,
                pais: doc.data().pais,
                cert: doc.data().cert,
                doc: doc.data().doc,
                grupo: doc.data().grupo,
                data: doc.data().delivery
            };

            array.push(Obj)
        });

        setRetorno2(array)
    }

    useEffect(() => {
        getResumos();

        if (user !== null) {
            user.providerData.forEach((profile) => {
                setEmail3(profile.email)
            });
        } 
    }, [])

    
    const getNome = async () => {
        
        const db = getFirestore();
        
        const citiesRef = collection(db, "Usuarios");
        const q = query(citiesRef, where("email", "==", email3));

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());
        
            setNome(doc.data().nome);
        });
    
}

    useEffect(() => {
        getResumos2();
    },[email])
    
    useEffect(() => {
        getNome();
    },[email3])

    useEffect(() => {
        getResumos();
    }, [open])

    function formatFirestoreTimestamp(timestamp) {
        const date = new Date(timestamp.seconds * 1000); // Converter segundos para milissegundos
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mês começa do 0
        const year = date.getFullYear();
    
        return `${day}/${month}/${year}`;
    }

    return (

        <View style={styles.container}>
        
        <View style={{flex: 1}}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Certificados - Intercept Course</Text>
                </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={40}
                        color="#ffffff"
                        style={{justifyContent: 'center', alignContent: 'center' }}
                    />
                </TouchableOpacity>

                <View style={styles.navBar}>

                    <TouchableOpacity style={styles.NavActive}> 
                        <Text style={styles.textNavActive}>Fila</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.NavDesable} onPress={() => navigation.navigate("InterceptCourseAprovados")}>
                        <Text style={styles.textNavDesable}>Aprovados</Text>
                    </TouchableOpacity>
                </View>
                
            </View>

            {open === true &&
                <View>
                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>

                            <TouchableOpacity style={styles.buttonFechar}
                                    onPress={() => {setOpen(false)}}
                                >
                                    <Text style={styles.textButtonFechar}>X</Text>
                            </TouchableOpacity>

                        
                            <View style={styles.divReturn}>
                                <Text style={styles.textReturnTitle}>{item.nome}</Text>
                                <Text style={styles.textReturnEmail}>{item.email}</Text>
                                <Text style={styles.textReturnEmail2}>{item.diplo}</Text>
                            </View>


                            <View style={styles.alinhadorDocs}>

                                <Text style={styles.textDoc}>Documento:</Text>

                                <ScrollView horizontal={true}>
                                    {item.doc[0] !== "" &&
                                        <TouchableOpacity style={styles.buttonDoc} onPress={() => {Linking.openURL(item.doc[0]);}}>
                                            <Text style={styles.textButtonDoc}>📄 Primeira Foto</Text>
                                        </TouchableOpacity>
                                    }

                                    {item.doc[1] !== "" &&
                                        <TouchableOpacity style={styles.buttonDoc} onPress={() => {Linking.openURL(item.doc[1]);}}>
                                            <Text style={styles.textButtonDoc}>📄 Segunda Foto</Text>
                                        </TouchableOpacity>
                                    }
                                </ScrollView>

                            </View>


                            <View style={styles.alinhadorDocs}>

                                <Text style={styles.textDoc}>Certificado:</Text>

                                <ScrollView horizontal={true}>
                                    {item.cert[0] !== "" &&
                                        <TouchableOpacity style={styles.buttonDoc} onPress={() => {Linking.openURL(item.cert[0]);}}>
                                            <Text style={styles.textButtonDoc}>📄 Primeira Foto</Text>
                                        </TouchableOpacity>
                                    }

                                    {item.cert[1] !== "" &&
                                        <TouchableOpacity style={styles.buttonDoc} onPress={() => {Linking.openURL(item.cert[1]);}}>
                                            <Text style={styles.textButtonDoc}>📄 Segunda Foto</Text>
                                        </TouchableOpacity>
                                    }
                                </ScrollView>

                            </View>

                            <View style={styles.alinhadorCet}>
                                <Image
                                    style={{width: 688, height: 960}}
                                    source={{uri: url + item.nome}}
                                />
                            </View>

                            <View style={styles.alinhadorEnviado}>

                            
                                <View style={styles.alinhadorEnviadoInterno}>
                                    {open2 === false &&
                                        <TouchableOpacity style={styles.buttonProblema} onPress={() => setOpen2(true)}>
                                            <Text style={styles.textProblema}>Reprovar</Text>
                                        </TouchableOpacity>
                                    }

                                    {open2 === false &&
                                        <TouchableOpacity style={styles.buttonEnv}
                                                onPress={async () => {

                                                    var encodedUrl = encodeURI(url + item.nome);

                                                    const db = getFirestore();
                                                    const docRef = await addDoc(collection(db, "Resumos"), {
                                                       
                                                        nome: item.nome,
                                                        email: item.email,
                                                        diplo: item.diplo,
                                                        certificado: encodedUrl,
                                                        telefone: item.telefone,
                                                        pais: item.pais,
                                                        cert: item.cert,
                                                        doc: item.doc,
                                                        grupo: item.grupo,
                                                        tipo: "Concluido"
                                                    });

                                                    await deleteDoc(doc(db, "Resumos", item.id));

                                                    axios.post('https://hook.us1.make.com/a18cpoku3axye093cgnkcu6956ex7qog', {
                                                        nome: item.nome,
                                                        email: item.email,
                                                        diplo: item.diplo,
                                                        certificado: encodedUrl,
                                                        telefone: item.telefone,
                                                        pais: item.pais,
                                                        cert: item.cert,
                                                        doc: item.doc,
                                                        grupo: item.grupo,
                                                        status: "✅"
                                                    })
                                                    .then(function (response) {
                                                        console.log(response);
                                                    })
                                                    .catch(function (error) {
                                                        console.error(error);
                                                    });

                                                    setOpen(false)
                                                }}
                                            >
                                                <Text style={styles.textEnv}>Aprovar</Text>
                                        </TouchableOpacity>
                                    }

                                    {open2 === true &&
                                        <TouchableOpacity style={styles.buttonProblema} onPress={() => setOpen2(false)}>
                                            <Text style={styles.textProblema}>Fechar</Text>
                                        </TouchableOpacity>
                                    }
                                </View>

                                {open2 === true &&
                                    <>
                                        <TextInput style={styles.input}
                                            placeholder="Motivo de reprovação..."
                                            placeholderTextColor="#000000"
                                            type="text"
                                            multiline = {true}
                                            numberOfLines = {4}
                                            onChangeText={(text) => setEmail2(text)}
                                            value={email2} />

                                        <TouchableOpacity style={styles.buttonProblema} onPress={async () => {
                                            const db = getFirestore();
                                            const docRef = await addDoc(collection(db, "Resumos"), {
                                                to: [item.email],
                                                message: {
                                                    subject: 'FC4 - Certificado',
                                                    html: '<p>Hola Doc. ' + (item.nome) + ', ¡saludos! </p><p>No puede recibir el certificado todavía.</p><p>Motivo: ' + (email2) +'</p><p>Seguimos en contacto.</p><p>Un abrazo,</p><p>'+{nome}+'</p><p>Equipo de Soporte Profª Rayane Pinto<br/></p> <div align="center"><img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150"></div>',
                                                },
                                                nome: item.nome,
                                                email: item.email,
                                                diplo: item.diplo,
                                                telefone: item.telefone,
                                                pais: item.pais,
                                                cert: item.cert,
                                                doc: item.doc,
                                                grupo: item.grupo,
                                                tipo: "Reporvado"
                                                
                                            });

                                            await deleteDoc(doc(db, "Resumos", item.id));

                                            axios.post('https://hook.us1.make.com/a18cpoku3axye093cgnkcu6956ex7qog', {
                                                nome: item.nome,
                                                email: item.email,
                                                diplo: item.diplo,
                                                telefone: item.telefone,
                                                pais: item.pais,
                                                cert: item.cert,
                                                doc: item.doc,
                                                grupo: item.grupo,
                                                status: "❌"
                                            })
                                            .then(function (response) {
                                                console.log(response);
                                            })
                                            .catch(function (error) {
                                                console.error(error);
                                            });

                                            setOpen(false)
                                            }}
                                        >
                                            <Text style={styles.textProblema}>Enviar</Text>
                                        </TouchableOpacity>
                                    </>
                                }
                            </View>

                        </>
                    )
                    })}
                </View>
            }

           {open === false &&
                <>
                    <View style={{flexDirection: 'row', justifyContent: "center", marginTop: 80}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Data</Text>
                            </View>
                            <View style={{backgroundColor: "#FAEDB5", width: 520, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Nome do aluno</Text>
                            </View>
                            <View style={{backgroundColor: "#FAEDB5", width: 500, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Email</Text>
                            </View>
                            <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Diplomado</Text>
                            </View>

                            <View style={{backgroundColor: "#FAEDB5", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Grupo</Text>
                            </View>

                            <View style={{backgroundColor: "#FAEDB5", width: 260, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Gerar Certificado</Text>
                            </View>
                    
                        </View>

                    
                            <ScrollView 
                                showsVerticalScrollIndicator={false}
                                style={{ marginBottom: 30}}
                            >

                                {retorno && retorno.map((item) => {
                                    return (
                                        <>
                                            <View style={{flexDirection: 'row', justifyContent: "center", cursor: "crosshair"}}>
                                                <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{formatFirestoreTimestamp(item.data.endTime)}</Text>
                                                </View>
                                                <View style={{backgroundColor: "#EDEDF4", width: 520, height: 60, margin: 5, borderRadius: 15}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.nome}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 500, height: 60, margin: 5, borderRadius: 15}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.email}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 7, fontWeight: "bold"}}>{item.diplo}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 150, height: 60, margin: 5, borderRadius: 15}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>{item.grupo}</Text>
                                                </View>
                
                                                <TouchableOpacity style={{backgroundColor: "#5077A1", width: 260, height: 60, margin: 5, borderRadius: 15}}
                                                    onPress={() => {setOpen(true), setEmail(item.email)}}
                                                >
                                                    <Text style={{color: "#fff", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Visualizar e Enviar</Text>
                                                </TouchableOpacity>
                                            
                                            </View>

                                        </>
                                )})}
                            </ScrollView>
                        </>
                        }
                    
            </View>
        </View>
    )
}