import React, { useState, useEffect } from 'react'
import { Text, View, TouchableOpacity, Image, TextInput, ScrollView, Linking } from 'react-native'
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { LinearGradient } from 'expo-linear-gradient';
import styles from './style';
import * as Animatable from 'react-native-animatable'
import { collection, query, where, getDocs, getFirestore, orderBy, addDoc, doc, deleteDoc} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import firebase from "../../../../Config/firebaseconfig.js"


export default function Trabalhos25({ navigation }) {

    const [retorno, setRetorno] = useState([]);
    const [retorno2, setRetorno2] = useState([]);
    const [url, setUrl] = useState("")
    const [email, setEmail] = useState([]);
    const [email2, setEmail2] = useState("");
    const [open, setOpen] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [email3, setEmail3] = useState("");
    const [nome, setNome] = useState("");

    const auth = getAuth();
    const user = auth.currentUser;

    const getResumos = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", "Trabalho OX - 25"));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                modalidade: doc.data().modalidade,
                area: doc.data().area,
                titulo: doc.data().titulo,
                chave: doc.data().chave,
                arquivo: doc.data().arquivo,
                nome: doc.data().nome,
                co1: doc.data().co1,
                co2: doc.data().co2,
                co3: doc.data().co3,
                co4: doc.data().co4,
                co5: doc.data().co5,
                dataAtual: doc.data().dataAtual,
                coFormatado: doc.data().coFormatado,
            };
            array.push(Obj)
        });

        setRetorno(array)
    }

    const getResumos2 = async () => {

        const db = getFirestore();
        const citiesRef = collection(db, "Resumos");
        const q = query(citiesRef, where("tipo", "==", "Trabalho OX - 25"), where("email", "==", email));
        let array = [];

        const querySnapshot = await getDocs(q);
        querySnapshot.forEach((doc) => {
        console.log(doc.id, " => ", doc.data());

            const Obj = {
                id: doc.id,
                email: doc.data().email,
                modalidade: doc.data().modalidade,
                area: doc.data().area,
                titulo: doc.data().titulo,
                chave: doc.data().chave,
                arquivo: doc.data().arquivo,
                arquivo_2: doc.data().arquivo_2,
                nome: doc.data().nome,
                co1: doc.data().co1,
                co2: doc.data().co2,
                co3: doc.data().co3,
                co4: doc.data().co4,
                co5: doc.data().co5,
                dataAtual: doc.data().dataAtual,
                coFormatado: doc.data().coFormatado,
            };

            array.push(Obj)
        });

        setRetorno2(array)
    }

    useEffect(() => {
        getResumos();
        if (user !== null) {
            user.providerData.forEach((profile) => {
                setEmail3(profile.email)
            });
        } 
    }, [])


    useEffect(() => {
        getResumos2();
    },[email])

    useEffect(() => {
        getResumos();
    }, [open])

    return (

        <LinearGradient style={styles.container}
                    start={{ x: 0, y: 1 }}
                    end={{ x: 1, y: 0 }}
                    colors={['#1E0337', '#49036F', '#3E0979','#270979', '#371EAA', '#8B00B2',]}>
        
        <View style={{flex: 1}}>
            <View style={styles.header}>
                <View>
                    <Text style={styles.Title}>Trabajos OX - 2025</Text>
                </View>

                <TouchableOpacity style={styles.buttomMenu} onPress={() => navigation.goBack()} >
                    <MaterialCommunityIcons
                        name="reply"
                        size={40}
                        color="#ffffff"
                        style={{justifyContent: 'center', alignContent: 'center'}}
                    />
                </TouchableOpacity>

                <View style={styles.navBar}>

                    <TouchableOpacity style={styles.NavActive}>
                        <Text style={styles.textNavActive}>Em Espera</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.NavDesable} onPress={() => navigation.navigate("Trabalhos_Aprovados25")}>
                        <Text style={styles.textNavDesable}>Aprovados</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.NavDesable} onPress={() => navigation.navigate("Trabalhos_Reprovados25")}>
                        <Text style={styles.textNavDesable}>Reprovados</Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.navBar_2}>

                    <TouchableOpacity style={styles.NavActive}>
                        <Text style={styles.textNavActive}>1º envio (word)</Text>
                    </TouchableOpacity>

                    <TouchableOpacity style={styles.NavDesable} onPress={() => navigation.navigate("Trabalhos_25_2")}>
                        <Text style={styles.textNavDesable}>2º envio (powerpoint)</Text>
                    </TouchableOpacity>

                </View>
                
            </View>

            {open === true &&
                <View>
                    {retorno2 && retorno2.map((item) => {
                    return (
                        <>

                            <TouchableOpacity style={styles.buttonFechar} onPress={() => {setOpen(false)}}>
                                <Text style={styles.textButtonFechar}>Fechar</Text>
                            </TouchableOpacity>

                        
                            <View style={styles.divReturn}>
                                <Text style={styles.textReturnTitle}>{item.titulo}</Text>
                                
                                <Text style={styles.textReturnName}>Autor: {item.nome}</Text>
                                <Text style={styles.textReturnEmail}>Email do Autor: {item.email}</Text>

                                <Text style={styles.textReturnArea}>Área: {item.area}</Text>

                                { item.co1 !== "" &&
                                    <View style={{marginTop: 30, marginBottom: 20}}>

                                        <Text style={styles.textReturnNameCo}>Co-Autor 1: {item.co1}</Text>
                                        {item.co2 !== "" &&
                                            <Text style={styles.textReturnNameCo}>Co-Autor 2: {item.co2}</Text>
                                        }

                                        {item.co3 !== "" &&
                                            <Text style={styles.textReturnNameCo}>Co-Autor 3: {item.co3}</Text>
                                        }

                                        {item.co4 !== "" &&
                                            <Text style={styles.textReturnNameCo}>Co-Autor 4: {item.co4}</Text>
                                        }

                                        {item.co5 !== "" &&
                                            <Text style={styles.textReturnNameCo}>Co-Autor 5: {item.co5}</Text>
                                        }
                                    </View>
                                }

                            <View style={styles.divBottom}>
                                <TouchableOpacity style={styles.upload} onPress={() => {Linking.openURL(item.arquivo)}}>
                                    <Text style={styles.textUpload}>Word</Text>
                                </TouchableOpacity>
                            
                            </View>

                            <View style={styles.divBottom}>
                                <View style={{height: 1, width: 940, borderTopColor: "#000", borderWidth: 1 }}></View>
                            </View>

                            <View style={styles.alinhadorEnviado}>
                            
                                <View style={styles.alinhadorEnviadoInterno}>
                                    {open2 === false &&
                                        <TouchableOpacity style={styles.buttonProblema} onPress={() => setOpen2(true)}>
                                            <Text style={styles.textProblema}>Reprovar</Text>
                                        </TouchableOpacity>
                                    }

                                    {open2 === false &&
                                        <TouchableOpacity style={styles.buttonEnv}
                                                onPress={async () => {

                                                  
                                                    const db = getFirestore();
                                                    const docRef = await addDoc(collection(db, "Resumos"), {
                                                        to: [item.email],
                                                        message: {
                                                            subject: 'OX25 – Trabajo aprobado',
                                                            html: `   <style>
                                                            *{
                                                               font-family: sans-serif;
                                                               font-Size: 15px
                                                            }
                                                            </style>
                                                            
                                                            <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FCAPA%20EMAIL.png?alt=media&token=63c241a6-0bc5-43c5-8f93-fdd4078c27bb" width="100%"/>
                                                            <p>Hola Doc. ` + (item.nome) + `, ¡saludos! </p>
                                                            <p>¡Felicitaciones, su trabajo:  "`+ (item.titulo) +`" ha sido <span style="color: #04A801;">ACEPTADO</span> para ser presentado en el III Congreso Internacional Odontología Sin Fronteras - Ortodoncia Experience!</p>

                                                            <p>Estamos enviando el template del póster (en powerpoint) que debe rellenar para presentar en el evento. Es importante que siga el template sin cambiar las medidas. Además, no es necesario imprimir el póster, sino llevarlo guardado en un pendrive.<br></p> 

                                                            <p><strong>Fecha de entrega: El póster debe ser enviado listo hasta el día 03/05 (viernes) a las 11:59 pm de Brasil.</strong><br></p> 

                                                            <p>Por el enlace: <a href="https://envio-de-trabajos.odontologiasinfronteras.com/">https://envio-de-trabajos.odontologiasinfronteras.com/</a></p>
                                                            
                                                            <p>Cualquier duda estamos a disposición.<br/><br/></p>
                                                            
                                                            <p>Cordialmente,<br>
                                                            Comisión Científica<br>
                                                            Ortodoncia Experience 2025<br>
                                                            III Congreso Internacional de Odontología sin Fronteras<br/></p>
                                                            <div align="center">
                                                                <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150">
                                                            </div>`,

                                                            attachments: [{
                                                                filename: "OX25_Template_para_presentación_de_trabajo.pptx",
                                                                path:"https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FOX25_Template%20para%20presentacio%CC%81n%20de%20trabajo.pptx?alt=media&token=9880bf1a-d04c-45a4-91d2-a63757433b3e"
                                                            }],
                                                        },
                                                        email: item.email,
                                                        modalidade: item.modalidade,
                                                        area: item.area,
                                                        titulo: item.titulo,
                                                        chave: item.chave,
                                                        arquivo: item.arquivo,
                                                        nome: item.nome,
                                                        co1: item.co1,
                                                        co2: item.co2,
                                                        co3: item.co3,
                                                        co4: item.co4,
                                                        co5: item.co5,
                                                        dataAtual: item.dataAtual,
                                                        coFormatado: item.coFormatado,  
                                                        tipo: "Trabalho OX - Concluido - 25"
                                                    });

                                                    await deleteDoc(doc(db, "Resumos", item.id));

                                                    setOpen(false)

                                                    setRetorno2([])
                                                }}
                                            >
                                                <Text style={styles.textEnv}>Aprovar</Text>
                                        </TouchableOpacity>
                                    }

                                    {open2 === true &&
                                        <TouchableOpacity style={styles.buttonProblema_2} onPress={() => setOpen2(false)}>
                                            <Text style={styles.textProblema}>Fechar</Text>
                                        </TouchableOpacity>
                                    }
                                </View>

                                {open2 === true &&
                                    <>
                                        <TextInput style={styles.input}
                                            placeholder="Motivo de reprovação..."
                                            placeholderTextColor="#000000"
                                            type="text"
                                            multiline = {true}
                                            numberOfLines = {4}
                                            onChangeText={(text) => setEmail2(text)}
                                            value={email2} />

                                        <TouchableOpacity style={styles.buttonProblema} onPress={async () => {
                                            const db = getFirestore();
                                            const docRef = await addDoc(collection(db, "Resumos"), {
                                                to: [item.email],
                                                message: {
                                                    subject: "OX25 – Trabajo Reprobado",
                                                    html: `  
                                                    <style>
                                                    *{
                                                       font-family: sans-serif;
                                                       font-Size: 15px
                                                    }
                                                  </style>
                                                  
                                                  <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/OX%2FCAPA%20EMAIL.png?alt=media&token=63c241a6-0bc5-43c5-8f93-fdd4078c27bb" width="100%"/>
                                                  <p>Hola Doc. ` + (item.nome) + `, ¡saludos! </p>
                                                  <p>La Comisión Científica del III Congreso Internacional Odontología Sin Fronteras - Ortodoncia Experience <span style="color: #F82500">REPROBÓ</span> su trabajo: `+ (item.titulo) +`</p>
                
                                                  <p>Razón:</p> 
                                                  <p>` + (email2) +`<br/></p>
                                                  
                                                  <p>Puede realizar los cambios solicitados una sola vez y volver a enviar el trabajo hasta el día 21/04/2024.<br/></p>
                                                  <p>Por el enlace: <a href="https://analisis-de-trabajo.odontologiasinfronteras.com/">https://analisis-de-trabajo.odontologiasinfronteras.com/</a></p>

                                                  <p>Cualquier duda estamos a disposición.<br/><br/></p>
                                                  
                                                  <p>Cordialmente,<br>
                                                  Comisión Científica<br>
                                                  Ortodoncia Experience 2025<br>
                                                  III Congreso Internacional de Odontología sin Fronteras<br/></p>
                                                  <div align="center">
                                                    <img src="https://firebasestorage.googleapis.com/v0/b/teste-5e945.appspot.com/o/Logos%2FLogo-OSF---PRETO%26VERDE%20(1).png?alt=media&token=4f22b716-ec57-4c71-bcfb-de67ef56b94d" width="150">
                                                  </div>`
                                                },
                                                email: item.email,
                                                modalidade: item.modalidade,
                                                area: item.area,
                                                titulo: item.titulo,
                                                chave: item.chave,
                                                arquivo: item.arquivo,
                                                nome: item.nome,
                                                co1: item.co1,
                                                co2: item.co2,
                                                co3: item.co3,
                                                co4: item.co4,
                                                co5: item.co5,
                                                dataAtual: item.dataAtual,
                                                coFormatado: item.coFormatado,
                                                tipo: "Trabalho OX - Reprovado - 25"
                                                
                                            });

                                            await deleteDoc(doc(db, "Resumos", item.id));
                                            setOpen(false)
                                            setRetorno2([])
                                            }}
                                        >
                                            <Text style={styles.textProblema}>Enviar</Text>
                                        </TouchableOpacity>
                                        
                                    </>
                                }
                            </View>
                            </View>

                        </>
                    )
                    })}
                </View>
            }

           {open === false &&
                <>
                    <View style={{flexDirection: 'row', justifyContent: "center", marginTop: 200}}>
                            <View style={{backgroundColor: "#FAEDB5", width: 520, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Nome do aluno</Text>
                            </View>
                            <View style={{backgroundColor: "#FAEDB5", width: 500, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Email</Text>
                            </View>
                            <View style={{backgroundColor: "#FAEDB5", width: 200, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Modalidae</Text>
                            </View>

                            <View style={{backgroundColor: "#FAEDB5", width: 340, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Área</Text>
                            </View>

                            <View style={{backgroundColor: "#FAEDB5", width: 260, height: 60, margin: 5, borderRadius: 15}}>
                                <Text style={{color: "#000", textAlign: "center", fontSize: 20, marginTop: 20, fontWeight: "bold"}}>Documentação</Text>
                            </View>
                    
                        </View>

                    
                            <ScrollView 
                                showsVerticalScrollIndicator={false}
                                style={{marginBottom: 60}}
                            >

                                {retorno && retorno.map((item) => {
                                    return (
                                        <>
                                            <View style={{flexDirection: 'row', justifyContent: "center", cursor: "crosshair"}}>
                                                <View style={{backgroundColor: "#EDEDF4", width: 520, height: 60, margin: 5, borderRadius: 15, alignItems: 'center', justifyContent: 'center'}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20,fontWeight: "bold", }}>{item.nome}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 500, height: 60, margin: 5, borderRadius: 15, alignItems: 'center', justifyContent: 'center'}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20,fontWeight: "bold"}}>{item.email}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 200, height: 60, margin: 5, borderRadius: 15, alignItems: 'center', justifyContent: 'center'}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20,fontWeight: "bold"}}>{item.modalidade}</Text>
                                                </View>

                                                <View style={{backgroundColor: "#EDEDF4", width: 340, height: 60, margin: 5, borderRadius: 15, alignItems: 'center', justifyContent: 'center'}}>
                                                    <Text style={{color: "#000", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>{item.area}</Text>
                                                </View>
                
                                                <TouchableOpacity style={{backgroundColor: "#0031E5", width: 260, height: 60, margin: 5, borderRadius: 15, alignItems: 'center', justifyContent: 'center'}}
                                                    onPress={() => {setOpen(true), setEmail(item.email)}}
                                                >
                                                    <Text style={{color: "#fff", textAlign: "center", fontSize: 20, fontWeight: "bold"}}>Visualizar</Text>
                                                </TouchableOpacity>
                                            
                                            </View>

                                        </>
                                )})}
                            </ScrollView>
                        </>
                        }
                    
            </View>
        </LinearGradient>
    )
}