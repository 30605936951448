import React, { useState, useEffect } from "react";
import { Text, View, TouchableOpacity, TextInput, Image, Modal } from "react-native";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { collection,query,where,getDocs,getFirestore,addDoc } from "firebase/firestore";
import * as DocumentPicker from "expo-document-picker";
import UploadFile from "../Function/PickImage";
import { Picker } from "@react-native-picker/picker";
import styles from "./style";

export default function MarketplaceCriacao({ navigation }) {
  const [nome, setNome] = useState("");
  const [foto, setFoto] = useState("");
  const [clinica, setClinica] = useState("");
  const [endereco, setEndereco] = useState("");
  const [tel, setTel] = useState("");
  const [email, setEmail] = useState("");
  const [insta, setInsta] = useState("");
  const [face, setFace] = useState("");
  const [cidade, setCidade] = useState("");
  const [cidade2, setCidade2] = useState("");
  const [pais, setPais] = useState("");
  const [botao, setBotao] = useState("Selecione uma Foto");

  const [cidades, setCidades] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState("");

  const getCidades = async () => {
    const db = getFirestore();
    const citiesRef = collection(db, pais);
    const q = query(citiesRef, where("nome", "!=", ""));
    let array = [];

    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      console.log(doc.id, " => ", doc.data());

      const Obj = {
        id: doc.id,
        nome: doc.data().nome,
      };
      array.push(Obj);
    });

    setCidades(array);
  };

  const pickImage = async () => {
    try {
      let result = await DocumentPicker.getDocumentAsync({
        type: "*/*",
        copyToCacheDirectory: false,
        multiple: false,
      });

      console.log(result);

      const mimeType = result.mimeType;
      const extensao = identificarExtensaoImagem(mimeType);

      if (!result.canceled) {
        setBotao("Espere...");
        var lastThree = result.name.substr(result.name.length - 3);

        const File = await UploadFile(result.uri, extensao, mimeType);
        getUploader();

        setFoto(File);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const hideAlert = () => {
    setModalVisible(false);
  };


  function identificarExtensaoImagem(mimeType) {
    const mapeamento = {
      "image/jpeg": ".jpg",
      "image/png": ".png",
      "image/gif": ".gif",
      "image/bmp": ".bmp",
      "image/webp": ".webp",
      "image/svg+xml": ".svg",
      "image/tiff": ".tiff",
      "image/vnd.microsoft.icon": ".ico",
      "image/x-icon": ".ico",
      "image/heic": ".heic",
      "image/heif": ".heif",
      "image/x-canon-cr2": ".cr2",
      "image/x-nikon-nef": ".nef",
      "image/x-sony-arw": ".arw",
      "image/x-fuji-raf": ".raf",
      "image/x-panasonic-rw2": ".rw2",
      "image/x-olympus-orf": ".orf",
      "image/x-pentax-pef": ".pef",
      // Adicione mais tipos MIME e extensões conforme necessário
    };

    return mapeamento[mimeType] || null;
  }

  const getUploader = async () => {
    if (loading === false && foto === "") {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const Enviar = async () => {
    try {
        if(foto !== "" && (cidade !== "" ||  cidade2 !== "") && pais !== "") {
          let CidadeFinal = "";
          if (cidade === "" && cidade2 !== "") {
            CidadeFinal = cidade2;
          } else {
            CidadeFinal = cidade;
          }
            const db = getFirestore();
            const docRef = await addDoc(collection(db, "Marketplace"), {
                foto: foto,
                nome: nome,
                clinica: clinica,
                endereco: endereco,
                tel: tel,
                email: email,
                insta: insta,
                face: face,
                cidade: CidadeFinal,
                pais: pais,
            });

            if(cidade === "" && cidade2 !== ""){
              const docRef2 = await addDoc(collection(db, pais), {
                nome: cidade2,
            });
            }
            setReload("Reload")
        } else {
            alert(
                "Preencha os campos: " + 
                [
                  foto === "" ? "Foto" : null,
                  cidade === "" ? "Cidade" : null,
                  pais === "" ? "País" : null
                ]
                .filter(Boolean)  // Remove os valores `null`
                .join(", ") + 
                " para enviar!"
              );
        }
      setModalVisible(false);
      setOpen(false);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  useEffect(() => {
    getCidades();
  }, [pais]);

  useEffect(() => {
    if (foto !== "") {
      getUploader();
    }
  }, [foto]);

  useEffect(() => {
    if(reload !== ""){
        setReload("");
    }

    setFoto("");
    setNome("");
    setEndereco("");
    setClinica("");
    setTel("");
    setEmail("");
    setInsta("");
    setFace("");
    setPais("");
    setCidade("");
    setCidade2("");
    setBotao("Selecione uma Foto");
  }, [reload]);

  console.log(cidades);

  return (
    <>
      <View style={styles.container}>
        <View style={styles.header}>
          <View>
            <Text style={styles.Title}>Marketplace (Cadastro)</Text>
          </View>
          <TouchableOpacity
            style={styles.buttomMenu}
            onPress={() => navigation.goBack()}
          >
            <MaterialCommunityIcons name="reply" size={40} color="#ffffff" />
          </TouchableOpacity>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "flex-start",
            marginTop: 200,
          }}
        >
          <View style={{ marginRight: 50 }}>
            <TextInput
              style={styles.input}
              placeholder="Nome"
              placeholderTextColor="#fff"
              type="text"
              onChangeText={(text) => setNome(text)}
              value={nome}
            />

            <TextInput
              style={styles.input}
              placeholder="Clínica"
              placeholderTextColor="#fff"
              type="text"
              onChangeText={(text) => setClinica(text)}
              value={clinica}
            />

            <TextInput
              style={styles.input}
              placeholder="Endereco (Sem cidade e pais)"
              placeholderTextColor="#fff"
              type="text"
              onChangeText={(text) => setEndereco(text)}
              value={endereco}
            />

            <TextInput
              style={styles.input}
              placeholder="Telefone (Apenas numeros)"
              placeholderTextColor="#fff"
              type="number"
              onChangeText={(text) => setTel(text)}
              value={tel}
            />

            <TextInput
              style={styles.input}
              placeholder="Email"
              placeholderTextColor="#fff"
              type="email"
              onChangeText={(text) => setEmail(text)}
              value={email}
            />

            <TextInput
              style={styles.input}
              placeholder="Instagram (Link)"
              placeholderTextColor="#fff"
              type="text"
              onChangeText={(text) => setInsta(text)}
              value={insta}
            />

            <TextInput
              style={styles.input}
              placeholder="Facebook (Link)"
              placeholderTextColor="#fff"
              type="text"
              onChangeText={(text) => setFace(text)}
              value={face}
            />
          </View>

          <View style={{ marginTop: 150 }}>
            <Picker
              selectedValue={pais}
              style={styles.picker}
              onValueChange={(itemValue) => setPais(itemValue)}
            >
              <Picker.Item label="Selcione um país" value="--" />
              <Picker.Item label="Argentina" value="Argentina" />
              <Picker.Item label="Bolívia" value="Bolívia" />
              <Picker.Item label="Chile" value="Chile" />
              <Picker.Item label="Colombia" value="Colombia" />
              <Picker.Item label="Costa Rica" value="Costa Rica" />
              <Picker.Item label="Ecuador" value="Ecuador" />
              <Picker.Item label="El Salvador" value="El Salvador" />
              <Picker.Item label="España" value="España" />
              <Picker.Item label="Guatemala" value="Guatemala" />
              <Picker.Item label="Honduras" value="Honduras" />
              <Picker.Item label="México" value="México" />
              <Picker.Item label="Nicaragua" value="Nicaragua" />
              <Picker.Item label="Panamá" value="Panamá" />
              <Picker.Item label="Paraguay" value="Paraguay" />
              <Picker.Item label="Perú" value="Perú" />
              <Picker.Item
                label="República Dominicana"
                value="República Dominicana"
              />
              <Picker.Item label="Uruguay" value="Uruguay" />
              <Picker.Item label="Venezuela" value="Venezuela" />
            </Picker>

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                justifyContent: "center",
                marginTop: 20,
                marginBottom: 30,
              }}
            >
              <Picker
                selectedValue={cidade}
                style={styles.picker}
                onValueChange={(itemValue) => setCidade(itemValue)}
              >
                <Picker.Item label="Selcione uma cidade" value="--" />
                {cidades &&
                  cidades.map((item, index) => {
                    return (
                      <Picker.Item
                        label={item.nome}
                        value={item.nome}
                        key={index}
                      />
                    );
                  })}
              </Picker>

                {open === false ?
                    <TouchableOpacity
                        style={styles.bottom2}
                        onPress={() => {setOpen(true)}}
                    >
                        <Text style={styles.textBottom}>Criar cidade</Text>
                    </TouchableOpacity>
                    :
                    <TextInput
                        style={styles.input2}
                        placeholder="Nome da cidade"
                        placeholderTextColor="#fff"
                        type="text"
                        onChangeText={(text) => setCidade2(text)}
                        value={cidade2}
                    />
                }

            </View>

            {foto === "" ? (
              <TouchableOpacity
                style={styles.bottom}
                onPress={() => {
                  pickImage();
                }}
              >
                <Text style={styles.textBottom}>{botao}</Text>
              </TouchableOpacity>
            ) : (
              <Image style={styles.img} source={foto} />
            )}
          </View>
        </View>

        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: 40,
          }}
        >
          <TouchableOpacity
            style={styles.buttomEnviar}
            onPress={() => {
              setModalVisible(true);
            }}
          >
            <Text style={{ color: "#FFF", textAlign: "center", fontSize: 18, fontWeight: "bold" }}>
              {" "}
              Enviar{" "}
            </Text>
          </TouchableOpacity>
        </View>

        <Modal
          transparent={true}
          animationType="slide"
          visible={modalVisible}
          onRequestClose={hideAlert}
        >
          <View style={styles.modalBackground}>
            <View style={styles.modalContainer}>
              <Text style={styles.modalText}>
                {clinica !== "" && endereco !== "" && tel !== "" && email !== "" && insta !== "" && face !== "" 
                    ? "Deseja realmente enviar?"
                    : "Os elementos: " + 
                        [
                            clinica === "" ? "Clínica" : null,
                            endereco === "" ? "Endereço" : null,
                            tel === "" ? "Telefone" : null,
                            email === "" ? "Email" : null,
                            insta === "" ? "Instagram" : null,
                            face === "" ? "Facebook" : null
                        ].filter(Boolean).join(", ") + " estão vazios, deseja enviar assim mesmo?."
                }
              </Text>
              <View style={styles.buttonContainer}>
                <TouchableOpacity
                  style={styles.modalButton}
                  onPress={async () => {
                    Enviar();
                  }}
                >
                  <Text style={styles.buttonText}>Sim</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={hideAlert}
                  style={styles.modalButton2}
                >
                  <Text style={styles.buttonText}>Não</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </Modal>

      </View>
    </>
  );
}
